<template>
  <v-container class="mainContainer" >
    <v-row class="topRow">
      <v-col cols="12" lg="4">
        <v-img src="@/assets/images/idea-logo.png" class="ideaLogo"></v-img>
      </v-col>
      <v-col cols="12" lg="4"></v-col>
      <v-col cols="12" lg="4"></v-col>
    </v-row>

    <v-row class="contentRow">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" lg="6" class="leftColumn">
            <v-img class="mainImage" src="@/assets/images/bg.png"></v-img>
            <v-img class="mainImage desktopHide" src="@/assets/images/bg3.png"></v-img>

          </v-col>

          <v-col cols="12" lg="6" class="rightColumn" v-if="mode==='question'" >
            <form @submit.prevent="generate">
            <v-row>
              <v-col>
                <span class="fieldLabel">Theme</span>
                <v-text-field
                label='e.g. Pokemon, Barbie or X category/industry'
                density="compact"
                variant="solo"
                single-line
                hide-details
                required
                v-model="theme"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <span class="fieldLabel">Objective</span>
                <v-text-field
                label='e.g. Social Media sharing or attract foot traffic'
                density="compact"
                variant="solo"
                single-line
                hide-details
                required
                v-model="objective"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <span class="fieldLabel">Outcome</span>
                <v-text-field label='e.g. Increase Sales, Engagement, Awareness, Retention'
                density="compact"
                variant="solo"
                single-line
                hide-details
                required
                v-model="outcome"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <button
                    type="submit"
                  class="apexl-button"
                >
                    Generate
              </button>
              </v-col>
            </v-row>
            </form>
          </v-col>

          <v-col cols="12" lg="6" class="rightColumn" v-if="mode==='loading'">
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
              >
            </v-progress-circular>
            <br/>
            <span class="loadingText">
              ...Initiating AI-powered brainstorming...brace yourself for awesomeness...
            </span>
         </v-col>

         <v-col cols="12" lg="6" class="rightColumn" v-if="mode==='result'">

          <h3>
            Results
          </h3>

          <div class="resultsContainer">
            <div class="result" v-for="i in ideas" :key="i">
              <h4>{{ i.title }}</h4>
              <p>{{ i.description }}</p>
              <a class="copyLink" @click="copyValueToClipboard(i.description)">Copy to clipboard </a>
            </div>
          </div>

            <a
              class="apexl-button"
              @click="generate"
            >
                REGENERATE
          </a>

            <a
              class="apexl-button"
              @click="reset"
            >
                RESET
          </a>

          <p style="text-align: center;margin-top: 1em; margin-bottom: 1em;">
            Want to see more great ideas by Apexl?
          </p>

          <a
              class="apexl-button"
              href="https://apexlstudios.com"
            >
                Click Here
          </a>

         </v-col>


        </v-row>

        <v-row class="footerRow">
          <v-col cols="4">
            <span>Powered by <a href="https://www.apexlstudios.com/immersion-studio">Immersion Studio</a></span>
          </v-col>
          <v-col cols="4">

          </v-col>
          <v-col cols="4" class="tandc">
            <span><a href="https://www.apexlstudios.com/privacy-policy">Privacy Policy</a></span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>



    <!-- <v-overlay
      :model-value="overlay"
      class="align-center justify-center"
      :close-on-content-click="false"

    >

    <span class="loadingText">
      ...Initiating AI-powered brainstorming...brace yourself for awesomeness...
    </span>
         <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      >
    </v-progress-circular>
    </v-overlay> -->


  </v-container>
</template>

<script>

import axios from 'axios';
// import fetch from 'node-fetch';
export default {
  name: 'HelloWorld',



  data: () => ({
    mode:'question',
    overlay:false,
    resultText:'',
    theme:'',
    objective:'',
    outcome:'',
    ideas:[]
  }),
  methods:{
    copyValueToClipboard(val) {
        navigator.clipboard.writeText(val).then(function() {
            // console.log('Text copied to clipboard ' + val);
          }, function(err) {
            console.error('Failed to copy text: ', err);
          });
      },
    reset(){
      this.resultText = '';
      this.theme = '';
      this.objective = '';
      this.outcome = '';
      this.mode = 'question';
    },
    generate(){

      if(this.theme.length >3 && this.objective.length >3 && this.outcome.length >3){

        this.overlay = true;
        this.mode = 'loading';


        axios.post(process.env.VUE_APP_API_BASE + 'generate', {
          theme: this.theme,
          objective: this.objective,
          outcome: this.outcome
        }, {
          headers: {
            'Content-Type': 'application/json'
          },
          mode: 'cors',
          crossDomain: true
        })
        .then(response => {
          const data = response.data;
          this.overlay = false;
          this.mode = 'result';
          this.resultText = data.result;
          this.ideas = JSON.parse(data.choices[0].message.content).ideas;
        })
        .catch(error => {
          console.log(error);
          this.overlay = false;
          this.mode = 'result';
          this.resultText = 'Oops! Something went wrong. Please try again.';
        });


        // fetch(process.env.VUE_APP_API_BASE + 'generate', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   mode: 'cors',
        //   crossDomain: true,
        //   body: JSON.stringify({
        //     theme: this.theme,
        //     objective: this.objective,
        //     outcome: this.outcome
        //   })
        // })
        // .then(response => response.json())
        // .then(data => {
        //   this.overlay = false;
        //   this.mode = 'result';
        //   this.resultText = data.result;
        //   this.ideas = JSON.parse(data.choices[0].message.content).ideas;
        // })
        // .catch(error => {
        //   console.log(error);
        //   this.overlay = false;
        //   this.mode = 'result';
        //   this.resultText = 'Oops! Something went wrong. Please try again.';
        // });

      }

    }
  }
}
</script>

<style>
.mainContainer{

}

.desktopHide{
  display: none;
}

body, body .v-application{
  background-color: #e5e4e3 !important;
}

.mainContainer .mainImage{
  height: 90vh;
}
.tandc{
  text-align: right;
}
.ideaLogo{
  position: fixed;
    left: 0;
    bottom: calc(100vh - 370px);
    width: 500px;
    z-index: 10;
}
.apexl-button{
  background-color: #927941;
    color: white;
    font-weight: bold;
    width: 30%;
    padding: 0.5em;
    border-radius: 2em;
    display: block;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    margin: auto auto 1em;
}

.leftColumn{

}
.rightColumn{
  margin-top: 15%;
}

.mdi-information-outline{
  margin-bottom: 0.2em;
}

.fieldLabel{
  margin-bottom: 0.5em;
  display: block;
  font-weight: bold;
}

.v-overlay__scrim{
  opacity: 0.8 !important;
  background: #edd0d0;
}
.loadingText{
  text-align: center;
  display: block;
}

.v-progress-circular
{
  display: block;
    margin-left: 50%;
    margin-top: 1em;
}
.resultsContainer{
  background: #ececec;
    border: 1px solid #c6bda0;
    padding: 0.5em;
    margin-bottom: 1em;
    max-height: 50vh;
    overflow: scroll;
}
.result .resultsContainer *{

}

.result .resultsContainer{

}

.result{
  margin-bottom: 1em;
}

.copyLink{
  color: #bba662;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
}

@media screen and (max-width: 999px) {
  .ideaLogo{
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    /* margin-bottom: -200px;
    width: 100%; */
  }

  .mainContainer .mainImage,
  .ideaLogo{
    display: none;
    height: 30vh;
  }

  .mainContainer .desktopHide{
    display: block;
  }

  .topRow{
    margin-top: -120px;
  }
  .rightColumn{
    margin-top: 0;
    padding-top: 0;
  }
}

</style>
